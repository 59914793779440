import { Link, StaticQuery, graphql, useStaticQuery, useState } from "gatsby";
import React, { Component } from "react";
import Layout from "../components/layout";
import "../css/sharan-style.css";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import PopupForm from "./popupForm";
import ReactHtmlParser from "react-html-parser";
import BarsSolidIcon from "../images/icons/bars-solid.svg";
import CalendarDaysRegularIcon from "../images/icons/calendar-days-regular.svg";
import PhoneFlipSolidIcon from "../images/icons/phone-flip-solid.svg";
import LaptopSolidIcon from "../images/icons/laptop-solid.svg";

import ReactGA from "react-ga";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: false,
      time: 90000000,
    };
    this.myFunction = this.myFunction.bind(this);
    this.hamFunction = this.hamFunction.bind(this);
  }

  onTabChange = (e) => {
    const tabHead = e.target.parentNode;
    if (tabHead) {
      const attr = tabHead.attributes.getNamedItem("data-content");
      if (tabHead.classList.contains("active")) {
        tabHead.classList.remove("active");
        if (attr && attr.value) {
          const contentId = attr.value;
          const tabContent = document.getElementById(contentId);
          tabContent.classList.remove("active");
        }
      } else {
        this.removePreviousTabSelection(() => {
          tabHead.classList.add("active");
          if (attr && attr.value) {
            const contentId = attr.value;
            const tabContent = document.getElementById(contentId);
            tabContent.classList.add("active");
          }
        });
      }
    }
  };

  removePreviousTabSelection = (fn) => {
    const activeTabHead = document.querySelector(
      ".navigation .navbar-nav > .nav-item.active"
    );
    const activeTabContent = document.querySelector(
      ".navigation .navbar-nav > .nav-item > .submenu.active"
    );
    if (activeTabHead) {
      activeTabHead.classList.remove("active");
    }
    if (activeTabContent) {
      activeTabContent.classList.remove("active");
    }
    fn();
  };

  onSubMenuTabChange = (e) => {
    this.removeSubMenuPreviousTabSelection(() => {
      const tabHead = e.target.parentNode;
      if (tabHead) {
        tabHead.classList.toggle("active");
        const attr = tabHead.attributes.getNamedItem("data-content");
        if (attr && attr.value) {
          const contentId = attr.value;
          const tabContent = document.getElementById(contentId);
          tabContent.classList.toggle("active");
        }
      }
    });
  };

  removeSubMenuPreviousTabSelection = (fn) => {
    const activeTabHead = document.querySelector(
      ".navigation .navbar-nav > .nav-item > .submenu > ul > li.active"
    );
    const activeTabContent = document.querySelector(
      ".navigation .navbar-nav > .nav-item > .submenu > ul > li > .submenu.active"
    );
    if (activeTabHead) {
      activeTabHead.classList.remove("active");
    }
    if (activeTabContent) {
      activeTabContent.classList.remove("active");
    }
    fn();
  };

  onSubInnerMenuTabChange = (e) => {
    this.removeSubInnerMenuPreviousTabSelection(() => {
      const tabHead = e.target.parentNode;
      if (tabHead) {
        tabHead.classList.toggle("active");
        const attr = tabHead.attributes.getNamedItem("data-content");
        if (attr && attr.value) {
          const contentId = attr.value;
          const tabContent = document.getElementById(contentId);
          tabContent.classList.toggle("active");
        }
      }
    });
  };

  removeSubInnerMenuPreviousTabSelection = (fn) => {
    const activeTabHead = document.querySelector(
      ".navigation .navbar-nav > .nav-item > .submenu > ul > li > .submenu > ul > li.active"
    );
    const activeTabContent = document.querySelector(
      ".navigation .navbar-nav > .nav-item > .submenu > ul > li > .submenu > ul > li > .submenu.active"
    );
    if (activeTabHead) {
      activeTabHead.classList.remove("active");
    }
    if (activeTabContent) {
      activeTabContent.classList.remove("active");
    }
    fn();
  };

  componentDidMount() {
    var delayInMilliseconds = 1000;
    window.addEventListener("scroll", this.handleScroll, true);

    const links = document.querySelectorAll("a");

    links.forEach((link) => {
      if (link.host !== window.location.host) {
        link.setAttribute("target", "_blank");
      }
    });

    setTimeout(function () {
      var preloader = document.getElementById("earna-preloader");
      let plainwhiteElement = document.getElementById("plainwhite");
      if (plainwhiteElement) plainwhiteElement.remove();
      preloader.classList.add("loaded");
    }, delayInMilliseconds);

    setTimeout(function () {
      var preloader = document.getElementById("preloader");
      if (preloader) preloader.remove();
    }, delayInMilliseconds * 4);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll(event) {
    if (window.pageYOffset > 450) {
      document.getElementById("header-top-bar").classList.add("hide-top-bar");
      document.getElementById("fixed-sec-top").classList.add("open");
    } else {
      document
        .getElementById("header-top-bar")
        .classList.remove("hide-top-bar");
      document.getElementById("fixed-sec-top").classList.remove("open");
    }
  }
  hamFunction = () => {
    this.setState({ click: !this.state.click });
  };

  myFunction = () => {
    var element = document.querySelector(".explore-menu");
    element.classList.toggle("active");
  };

  InstitutionsFunction = () => {
    let element = document.getElementById("programs-menu");

    element.classList.remove("active");
  };

  handelsubmit = () => {
    this.props.history.push({
      pathname: "/programlisting",
      search: "country=Australia",
      state: { Country: "Australia" },
    });
  };

  handlePopupWindow = () => {
    this.setState({ time: Math.random() });
  };
  handleCTAClick = () => {
    localStorage.setItem("isPopupShown", "Never");
  };

  handleTimeChange = () => {
    this.setState({ time: Math.random() });
  };

  render() {
    const isActive = this.state.isActive;
    return (
      <div class="wrapper">
        {typeof window !== "undefined"}
        <PopupForm
          time={this.state.time}
          handleTimeChange={this.handleTimeChange}
        />
        <div
          id="plainwhite"
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            left: "0",
            top: "0",
            background: "#fff",
            zIndex: "2044444555544",
          }}
        >
          {" "}
        </div>
        <div id="preloader" class="newStyle">
          <div id="earna-preloader" class="earna-preloader">
            <div class="animation-preloader">
              <div class="spinner"></div>
              <img
                src="https://assets.theworldgrad.com/gatsby-assets/images/logo.png"
                class="logo"
                alt="Logo"
                style={{ maxWidth: "240px" }}
              />
            </div>
            <div class="loader">
              <div class="row">
                <div class="col-3 loader-section section-left">
                  <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-left">
                  <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                  <div class="bg"></div>
                </div>
                <div class="col-3 loader-section section-right">
                  <div class="bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p id="fixed-sec-top" class="fixed-sec-top newStyle">
          <a
            onClick={() => {
              this.handlePopupWindow();
              this.handleCTAClick();
              ReactGA.event({
                category: "Home Page",
                action: "Clicks",
                label: "Enquire",
              });
            }}
            class="btn btn-theme-rev effect btn-md modal-popup mt-0"
            href="#get-in-touch"
            id="book-an-appointment"
          >
            {" "}
            <img
              src={CalendarDaysRegularIcon}
              alt="Icon"
              className="left-icon newicon"
            />{" "}
            CONNECT WITH US
          </a>
        </p>
        <div id="newheader" class="newStyle">
          <div
            id="header-top-bar"
            class="top-bar-area top-bar-style-six-area text-light"
          >
            <div class="">
              <div class="top-bar-style-two">
                <div class="row align-center">
                  <div class="col-md-3 ">
                    <a
                      class="text-white headerphone"
                      onClick={() => this.handleCTAClick()}
                      href="tel:080-45681763"
                      title="080-45681763"
                    >
                      {" "}
                      <img
                        src={PhoneFlipSolidIcon}
                        alt="Icon"
                        className="left-icon newicon"
                      />{" "}
                      080-45681763
                    </a>
                  </div>

                  <div class="col-md-9 text-right item-flex">
                    <div class="info">
                      <ul>
                        {this.props?.rightMenu
                          ?.slice(0, 1)
                          .map((item, index) => {
                            return (
                              <li>
                                <a
                                  href={item.url}
                                  onClick={(event) => {
                                    event.preventDefault(); // Prevent the default anchor link behavior
                                    this.handleCTAClick();
                                    window.location.href = item.url; // Manually redirect after function execution
                                  }}
                                  className={`text-white ${item.classes}`}
                                  target={item.target}
                                >
                                  {item.title.includes("EVENTS") && (
                                    <img
                                      src={CalendarDaysRegularIcon}
                                      alt="Icon"
                                      className="left-icon newicon"
                                    />
                                  )}
                                  {item.title.includes("COUNSELLING") && (
                                    <img
                                      src={LaptopSolidIcon}
                                      alt="Icon"
                                      className="left-icon newicon newicon-large"
                                    />
                                  )}
                                  {ReactHtmlParser(item.title)}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <header id="home" class="border-bottom border-color-medium-gray p-0">
            <nav class="navbar navbar-default navbar-sticky bootsnav menu-center">
              <div class="container-header">
                <div className="attr-nav">
                  <a
                    onClick={() => {
                      this.handlePopupWindow();
                      this.handleCTAClick();
                    }}
                    className="btn btn-theme-rev effect btn-md modal-popup"
                    id="connect-with-us-btn"
                    href="#get-in-touch"
                  >
                    <img
                      src={CalendarDaysRegularIcon}
                      alt="Icon"
                      className="left-icon newicon"
                    />{" "}
                    CONNECT WITH US
                  </a>
                </div>
                <div class="navbar-header">
                  <button
                    onClick={() => this.hamFunction()}
                    type="button"
                    class="navbar-toggle"
                    data-toggle="collapse"
                    data-target="#navbar-menu"
                  >
                    <img
                      src={BarsSolidIcon}
                      alt="Menu"
                      className="left-icon newicon newicon-black"
                    />
                  </button>
                  <a class="navbar-brand" href="/">
                    <img
                      src="https://assets.theworldgrad.com/gatsby-assets/images/logo.png"
                      class="logo"
                      alt="Logo"
                      width="175"
                      height="50"
                    />
                  </a>
                </div>

                <div
                  className={
                    "collapse navbar-collapse navigation" +
                    " " +
                    (this.state.click == true ? "in on" : "")
                  }
                  id="navbarSupportedContent"
                >
                  <ul class="nav navbar-nav navbar-center">
                    {this.props?.menuitem?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item"
                          data-content={"nav-" + index}
                        >
                          <a
                            className="nav-link"
                            onClick={(e) => this.onTabChange(e)}
                            href={item.url}
                            target={item.target}
                          >
                            {item.title}
                          </a>
                          {item.wordpress_children !== null ? (
                            <img
                              onClick={(e) => this.onTabChange(e)}
                              src="https://assets.theworldgrad.com/gatsby-assets/xtra/down-arrow_hndcah.svg"
                              alt="↓"
                            />
                          ) : (
                            ""
                          )}
                          <div class="submenu" id={"nav-" + index}>
                            <ul>
                              {item?.wordpress_children?.map(
                                (subitem, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="nav-item"
                                      data-content={"nav-submenu" + index}
                                    >
                                      <a
                                        className="nav-link"
                                        href={subitem.url}
                                        target={subitem.target}
                                      >
                                        {subitem.title}
                                      </a>
                                      {subitem.wordpress_children !== null ? (
                                        <img
                                          onClick={(e) =>
                                            this.onSubMenuTabChange(e)
                                          }
                                          src="https://res.cloudinary.com/worldgrad/image/upload/v1612202440/worldgrad/right-arrow_ny7ss3.svg"
                                          alt="→"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {subitem.wordpress_children !== null ? (
                                        <div
                                          className="submenu"
                                          id={"nav-submenu" + index}
                                        >
                                          <ul>
                                            {subitem?.wordpress_children?.map(
                                              (iteminner, index) => {
                                                return (
                                                  <li
                                                    key={index}
                                                    className="nav-item"
                                                    data-content={
                                                      "nav-subinnermenu" + index
                                                    }
                                                  >
                                                    <a
                                                      className="nav-link"
                                                      href={iteminner.url}
                                                      target={iteminner.target}
                                                    >
                                                      {iteminner.title}
                                                    </a>
                                                    {iteminner.wordpress_children !==
                                                    null ? (
                                                      <img
                                                        onClick={(e) =>
                                                          this.onSubInnerMenuTabChange(
                                                            e
                                                          )
                                                        }
                                                        src="https://res.cloudinary.com/worldgrad/image/upload/v1612202440/worldgrad/right-arrow_ny7ss3.svg"
                                                        alt=" → "
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    {iteminner.wordpress_children !==
                                                    null ? (
                                                      <div
                                                        className="submenu"
                                                        id={
                                                          "nav-subinnermenu" +
                                                          index
                                                        }
                                                      >
                                                        <ul>
                                                          {iteminner?.wordpress_children?.map(
                                                            (
                                                              subitem2,
                                                              index
                                                            ) => {
                                                              return (
                                                                <li
                                                                  key={index}
                                                                  className="nav-item"
                                                                >
                                                                  <a
                                                                    className="nav-link"
                                                                    href={
                                                                      subitem2.url
                                                                    }
                                                                    target={
                                                                      subitem2.target
                                                                    }
                                                                  >
                                                                    {
                                                                      subitem2.title
                                                                    }
                                                                  </a>
                                                                </li>
                                                              );
                                                            }
                                                          )}
                                                        </ul>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div class="d-lg-none margin-20px-bottom mobile-menu-btns">
                    <ul>
                      <li class="d-flex">
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleCTAClick();
                            window.location.href = "/event-listing";
                          }}
                          class="btn btn-md btn-theme-rev effect margin-10px-right margin-15px-left mt-0"
                          target=""
                        >
                          <img
                            src={CalendarDaysRegularIcon}
                            alt="Icon"
                            className="left-icon newicon"
                          />
                          EVENTS
                        </a>
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
    );
  }
}
export default Header;
